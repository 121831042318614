<template>
  <div>
    <div class="loading">
      <div class="people-bg"></div>
      <div class="search">
        <div class="search-img"></div>
      </div>
    </div>

    <div class="line-ani">
      <div class="pop" :style="{ left: persent + '%' }">{{ persent }}%</div>
      <div class="line-active" :style="{ width: persent + '%' }"></div>
    </div>

    <div class="state-txt">
      <div class="flex-row">
        <div :class="step >= 1 ? 'txt-active' : ''">身份信息确认</div>
        <div :class="step >= 1 ? 'prepare-active' : 'prepare'"></div>
      </div>
      <div class="flex-row">
        <div :class="step >= 2 ? 'txt-active' : ''">正在提交资料</div>
        <div :class="step >= 2 ? 'prepare-active' : 'prepare'"></div>
      </div>
      <div class="flex-row">
        <div :class="step >= 3 ? 'txt-active' : ''">正在进行初审</div>
        <div :class="step >= 3 ? 'prepare-active' : 'prepare'"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "stateWatingModel6",
  data() {
    return {
      persent: 0,
      step: 1,
    };
  },
  mounted() {
    this.initState();
    this.autoToHref();
  },
  methods: {
    initState() {
      var that = this;
      var intervalFunc = setInterval(function () {
        if (that.persent === 99) clearInterval(intervalFunc);
        that.persent++;
        that.modifyTxt(that.persent);
        // that.persentWidth++;
      }, 3000 / 75);
    },
    autoToHref() {
      var that = this;
      setTimeout(function () {
        that.$router.push({
          name: "stagingAssessment",
          query: {
            source: that.$route.query.source,
          },
        });
      }, 3000);
    },
    modifyTxt(num) {
      switch (num) {
        case 0:
          this.step = 1;
          break;
        case 30:
          this.step = 2;
          break;
        case 100:
          this.step = 3;

          break;
      }
    },
    // animateState() {
    //   var that = this;
    //   return setInterval(function () {
    //     if (that.persent === 1000) clearInterval(that.animateState);
    //     this.persent++;
    //   }, 3000 / 75);
    // }
  },
};
</script>

<style lang="scss" scoped>
#vue {
  height: 100vh;
  overflow: auto;
  background: url("../assets/images/stateWating/bg.png") no-repeat;
  background-size: cover;
  /* padding: 0.3rem 0.35rem; */
}

.loading {
  position: relative;
  /* display: flex; */
}

.people-bg {
  width: 1.56rem;
  /* 86px; */
  height: 2.18rem;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 1rem;
  background: url("../assets/images/stateWating/people.png") no-repeat;
  background-size: cover;
}

.search {
  width: 1rem;
  height: 1.27rem;
  margin: 0 auto;
  margin-top: -1.7rem;

  position: relative;
  /* left: 0.8rem; */
  transform-origin: 30px 30px;
  animation: spin linear 3s infinite;
}

.search-img {
  width: 1rem;
  height: 1.27rem;
  background: url("../assets/images/stateWating/query.png") no-repeat;
  background-size: cover;
  animation: spin reverse linear 3s infinite;
}

@keyframes spin {
  to {
    transform: rotateZ(360deg);
  }
}

.line-ani {
  position: relative;
  width: 80vw;
  top: 1.5rem;
  height: 2px;
  margin: 0 auto;
  border: 2px solid #ceddfc;
  border-radius: 1px;
}

.line-active {
  height: 2px;
  /* width: 20vw; */
  border: 2px solid #3b75f6;
  margin-top: -2.5px;
  /* animation: myfirst 4s 1; */
}

/* @keyframes myfirst {
  0% {
    width: 20vw;
  }

  100% {
    width: 80vw;
  }

} */

.pop {
  width: 0.5rem;
  height: 0.3rem;
  /* margin: 0 auto; */
  margin-top: -0.4rem;
  /* left: 20%; */
  background: url("../assets/images/stateWating/pop.png") no-repeat;
  background-size: cover;
  position: absolute;
  color: #ffffff;
  line-height: 0.3rem;
  font-size: 0.2rem;
  margin-left: -15px;
}

.state-txt {
  width: 80vw;
  position: relative;
  margin: 0 auto;
  top: 2rem;
}

.flex-row {
  display: flex;
  font-size: 0.29rem;
  margin-top: 25px;
  justify-content: space-between;
  color: #babdd0;
}

.txt-active {
  color: #000000;
}

.prepare {
  width: 0.32rem;
  height: 0.3rem;
  background: url("../assets/images/stateWating/prepare.png") no-repeat;
  background-size: cover;
}

.prepare-active {
  width: 0.32rem;
  height: 0.3rem;
  background: url("../assets/images/stateWating/prepare-active.png") no-repeat;
  background-size: cover;
}
</style>
